<template>
  <div class="eldialogbody">
    <!-- <div class="tip">
      <div class="icon">!</div>
      <div class="tiptext">预约提示内容!</div>
    </div>-->
    <div class="contain">
      <div class="left">
        <el-form ref="form" :rules="rules" :model="form" label-width="90px">
          <el-form-item label="预约人:">
            <el-input class="elinput" v-model="userinfo.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="实验室:">
            <el-input class="elinput" v-model="roominfo.lab_name" disabled></el-input>
          </el-form-item>

          <el-form-item label="日期:">
            <el-input class="elinput" v-model="date" disabled></el-input>
          </el-form-item>

          <el-form-item class="elinput" label="预约要求:">
            <el-radio-group v-model="roominfo.book_type">
              <el-radio :label="1" :disabled="roominfo.book_type != 1">实验位</el-radio>
              <el-radio :label="2" :disabled="roominfo.book_type != 2">实验室</el-radio>
              <el-radio :label="3" :disabled="roominfo.book_type != 3">容纳人数</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="预约时间:">
            <!-- <template v-if="setting_list && setting_list.length > 0">
              <div>
                请从以下时间段选择:
                <span
                  v-for="(item, index) in setting_list"
                  :key="index"
                >{{ item.start_time + "~" + item.end_time + "," }}</span>
              </div>
            </template>-->

            <el-time-select
              class="elinput2"
              placeholder="起始时间"
              v-model="form.start_time"
              @focus="setMinTime(date)"
              :picker-options="{ start: initStartTime ? initStartTime : '00:00', step: '00:30', end: '23:30', minTime: dateTime, maxTime: initEndTime,}"
            ></el-time-select>
            <span style="width: 26px; text-align: center; display: inline-block;">至</span>
            <el-time-select
              class="elinput2"
              placeholder="结束时间"
              v-model="form.end_time"
              :disabled="!form.start_time"
              :picker-options="{ start: '00:00', step: '00:30', end: initEndTime ? initEndTime : '23:30', minTime: form.start_time,}"
            ></el-time-select>
          </el-form-item>

          <el-form-item class="elinput" label="实验方案:">
            <UploadFiles
              :files="fileList"
              :module="'open_room_book/plan'"
              :cloudType="'openRoomBook/plan'"
              @setUrl="getUrl"
            />
          </el-form-item>

          <el-form-item label="实验说明:" prop="remark">
            <el-input
              class="elinput"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              v-model="form.remark"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="centerline"></div>
      <div class="right">
        <div
          class="title"
        >◀ {{ dataarr[0] }}年{{ dataarr[1] }}月{{ dataarr[2] }}日 星期{{ week | weekformat }} ▶</div>
        <div class="tips">
          <div class="icons"></div>
          <div class="tiptexts">已被预约</div>
        </div>
        <!-- 可视化图表展示 -->
        <div class="chartcontainer">
          <div class="maincontain">
            <div class="basemap">
              <div
                class="item"
                v-for="(item, index) in 18"
                :style="{ top: -10 + (item - 1) * 60 + 'px' }"
                :key="index"
              >
                <div class="num">{{ (item - 1 + 6) | formattime }}</div>
                <div class="line"></div>
              </div>
            </div>
            <div class="areamap" v-if="roominfo.book_type == 1">
              <div
                class="item"
                v-for="(item, index) in book_list"
                :key="index"
                :style="{ top: Number(item.start_time_str.slice(0,2))*60 + Number(item.start_time_str.slice(-2))-360 + 'px'}"
              >
                <div
                  :class="['cont',item.count == station_num ? 'dang':'warn']"
                  v-if="item.count > 0"
                  :style="{height: (item.end_time - item.start_time)/60 + 'px'}"
                ></div>
              </div>
            </div>
            <div class="areamap" v-if="roominfo.book_type == 2">
              <div
                class="item"
                v-for="(item, index) in book_list"
                :key="index"
                :style="{ top: item.start_time - 360 + 'px',}"
              >
                <div
                  :class="['cont','warn']"
                  :style="'height:' + Number(item.end_time - item.start_time) + 'px'"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const weektype = ["日", "一", "二", "三", "四", "五", "六"]
import { getuserinfo } from "@/api/user"
import dayjs from "dayjs"
import { openroomconflictdetection, gettimeinfo, gettimepositioninfo } from "@/api/openroom"
import { openroombook } from "@/api/openroombook"
import UploadFiles from "@/components/upload-files.vue"
export default {
  name: "Openroom",
  props: {
    closedialog: {
      type: Function,
    },
  },
  components: {
    UploadFiles,
  },

  filters: {
    weekformat (val) {
      return weektype[val]
    },
    dataformat (value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    },
    formattime (value) {
      if (value < 10) {
        return "0" + value + ":" + "00"
      } else {
        return value + ":" + "00"
      }
    },
  },

  data () {
    return {
      issubmit: false,
      roomid: "",
      roominfo: "",
      date: "",
      date2: "",
      dataarr: [],
      week: "",
      userinfo: "",
      form: {
        timevalue: "",
        start_time: "",
        end_time: "",
        plan: "",
        remark: "",
      },
      dateTime: null,
      rules: {
        remark: [
          { required: true, message: "请输入实验说明", trigger: "blur" },
        ],
      },
      fileList: [],
      start_time: "",
      end_time: "",
      book_list: [],
      setting_list: [],
      station_num: null,
      initStartTime: "",
      initEndTime: "",
    }
  },
  watch: {
    setting_list: {
      handler (newV) {
        if (newV.length) {
          let minArr = []
          let res_minArr = []
          let maxArr = []
          let res_maxArr = []
          newV.forEach(item => {
            minArr.push(item.start_time)
            maxArr.push(item.end_time)
          })
          res_minArr = [...new Set(minArr)]
          res_maxArr = [...new Set(maxArr)]
          let sortMinArr = res_minArr.sort((a, b) => { // yy-mm-dd 随意取值
            return Date.parse(`2023-9-27 ${a}`) - Date.parse(`2023-9-27 ${b}`)
          })
          let sortMaxArr = res_maxArr.sort((a, b) => {
            return Date.parse(`2023-9-27 ${a}`) - Date.parse(`2023-9-27 ${b}`)
          })
          this.initStartTime = sortMinArr[0] // 取开始时间最早的值
          this.initEndTime = sortMaxArr[sortMaxArr.length - 1] // 取结束时间最晚的值
        }
      },
      immediate: true,
      deep: true
    }
  },
  destroyed () {
    this.form.timevalue = ""
    this.form.start_time = ""
    this.form.end_time = ""
    this.form.plan = ""
    this.form.remark = ""
  },

  methods: {
    initdata (roomid, roominfo, date, week, date2) {
      this.roomid = roomid
      this.roominfo = roominfo
      // this.date = dayjs.unix(date).format("YYYY-MM-DD HH:mm:ss");
      this.date = date.day
      this.date2 = date2.toDateString()
      // console.log('当前日期:',date2.toDateString())
      this.dataarr = date.day.split("-")
      this.week = week

      this.getuserinfo()

      this.gettimeinfo({
        open_room_id: roomid,
        time: date.day.split("-").join(""),
      })
    },
    // 设置起始小时
    setMinTime (date) {
      let set = new Date(date)
      let now = new Date()
      if (set.getDate() == now.getDate()) {
        this.dateTime = now.getHours() + ':' + now.getMinutes()
      }
    },

    submitbt () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.start_time = new Date(this.date2 + " " + this.form.start_time).getTime() / 1000
          this.end_time = new Date(this.date2 + " " + this.form.end_time).getTime() / 1000
          let params = {
            open_room_id: this.roomid,
            date: this.date.split("-").join(""),
            start_time: this.start_time,
            end_time: this.end_time,
          }
          this.openroomconflictdetection(params)
        } else {
          return false
        }
      })
    },
    //提交预约
    openroombook (params) {
      openroombook(params)
        .then((response) => {
          this.issubmit = false
          if (response.code === 0) {
            this.$message({
              message: '预约成功!',
              type: 'success'
            })
            this.closedialog()
          }
        })
        .catch((error) => {
          //console.log(error)
        })
    },
    //获取登录用户信息
    getuserinfo (params) {
      getuserinfo(params)
        .then((response) => {
          if (response.data) {
            this.userinfo = response.data
          }
        })
        .catch((error) => {
          //console.log(error)
        })
    },

    //开放用房预约冲突检测
    openroomconflictdetection (params) {
      openroomconflictdetection(params)
        .then((response) => {
          if (response.code === 0) {
            this.openroombook({
              open_room_id: this.roomid,
              date: this.date.split("-").join(""),
              start_time: this.start_time,
              end_time: this.end_time,
              remark: this.form.remark,
              plan: this.form.plan,
            })
          }
        })
        .catch((error) => {
          //console.log(error)
        })
    },
    //获取当前实验室预约时间段详情
    gettimeinfo (params) {
      this.book_list = []
      this.setting_list = []
      this.station_num = null
      let api = ""
      if (this.roominfo.book_type == 2) {
        api = gettimeinfo
      } else if (this.roominfo.book_type == 1) {  // book_type: 预约方式，0：不限，1：实验位，2：实验室, 3: 容纳人数;
        params.type = 0  // type 0,获取实验位详情; 1,获取容纳人数详情
        api = gettimepositioninfo
      } else if (this.roominfo.book_type == 3) {
        params.type = 1  // type 0,获取实验位详情; 1,获取容纳人数详情
        api = gettimepositioninfo
      }
      // 活动API
      api(params).then((response) => {
        if (response.code === 0) {
          this.book_list = response.data.book_list
          this.setting_list = response.data.setting_list
          this.station_num = response.data.station_num
        }
      }).catch((error) => {
        //console.log(error)
      })
    },
    // 附件
    getUrl (src, key) {
      this.form.plan = src
      this.fileList = JSON.parse(src)
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .eldialogbody {
  .tip {
    height: 35px;
    background: rgba(230, 247, 255, 1);
    border: 1px solid rgba(145, 213, 255, 1);
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon {
      margin-left: 20px;
      margin-right: 10px;
      width: 17px;
      height: 17px;
      line-height: 17px;
      text-align: center;
      border-radius: 50%;
      background: #1890ff;
      color: white;

      .tiptext {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  .contain {
    // display: flex;
    // justify-content: flex-start;
    // align-items: flex-start;

    .left {
      margin-top: 10px;
      // padding-left: 10px;
      width: 100%;

      .elinput {
        width: 100%;
      }

      .elinput2 {
        width: calc((80% - 26px) / 2);
      }

      .timeline {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .centerline {
      margin-top: 20px;
      // height: 540px;
      border-bottom: 1px solid #cccccc;
    }

    .right {
      margin-left: 10px;
      flex: 1;
      height: 45%;

      .title {
        margin-top: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #999999;
        text-align: center;
        user-select: none;
        white-space: nowrap;
      }

      .tips {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 40px;
        margin-top: 10px;
        margin-bottom: 10px;

        .icons {
          margin-right: 6px;
          width: 29px;
          height: 15px;
          background: #e6a23c;
        }

        .tiptexts {
          font-size: 16px;
          font-weight: 400;
          color: #a7a7a7;
        }
      }

      .timeitem-line {
        margin-left: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .time-line {
          margin-left: 10px;
          margin-right: 10px;
          flex: 1;
          border-bottom: 1px solid #cccccc;
        }
      }

      .chartcontainer {
        width: 100%;
        height: 460px;
        padding: 0px;
        overflow-y: auto;

        .maincontain {
          margin: 10px 10px 10px 60px;
          position: relative;
          .basemap {
            width: 100%;
            min-height: 1020px;
            box-sizing: border-box;
            border: 1px solid #cccccc;
            border-bottom-color: transparent;
            position: relative;
            .item {
              width: calc(100% + 40px);
              top: -10px;
              left: -40px;
            }
          }
          .areamap {
            width: 100%;
            min-height: 30px;
            position: absolute;
            top: 0;
            z-index: 1;
            .item {
              width: 100%;
              top: 10px;
            }

            // position: absolute;
            .warn {
              background-color: #e6a23c;
            }
            .dang {
              background-color: #f56c6c;
            }
          }

          .item {
            position: absolute;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .num {
              user-select: none;
              white-space: nowrap;
            }
            .line {
              width: 100%;
              border-bottom: 1px solid #cccccc;
            }
            .cont {
              width: 100%;
            }
          }
          .item2 {
            position: absolute;
            top: 51px;
            left: -40px;
            width: calc(100% + 40px);
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .num {
              user-select: none;
              white-space: nowrap;
            }

            .line {
              width: 100%;
              // border-bottom: 1px solid red;
            }
          }
        }
      }

      .tip2 {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 6px;
        height: 26px;
        background: #fffbe6;
        border: 1px solid #ffaa00;
        border-radius: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
          margin-left: 20px;
          margin-right: 10px;
          width: 17px;
          height: 17px;
          line-height: 17px;
          text-align: center;
          border-radius: 50%;
          background: #ffaa00;
          color: white;

          .tiptext {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
}

::v-deep .el-upload-list__item-name {
  width: 60%;
}
}
@media screen and (min-width: 1200px) {
  .eldialogbody {
  .tip {
    height: 35px;
    background: rgba(230, 247, 255, 1);
    border: 1px solid rgba(145, 213, 255, 1);
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon {
      margin-left: 20px;
      margin-right: 10px;
      width: 17px;
      height: 17px;
      line-height: 17px;
      text-align: center;
      border-radius: 50%;
      background: #1890ff;
      color: white;

      .tiptext {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  .contain {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .left {
      margin-top: 40px;
      padding-left: 40px;
      width: 50%;

      .elinput {
        width: 80%;
      }

      .elinput2 {
        width: calc((80% - 26px) / 2);
      }

      .timeline {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .centerline {
      margin-top: 20px;
      height: 540px;
      border-right: 1px solid #cccccc;
    }

    .right {
      margin-left: 20px;
      flex: 1;
      height: 45%;

      .title {
        margin-top: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #999999;
        text-align: center;
        user-select: none;
        white-space: nowrap;
      }

      .tips {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 40px;
        margin-top: 10px;
        margin-bottom: 10px;

        .icons {
          margin-right: 6px;
          width: 29px;
          height: 15px;
          background: #e6a23c;
        }

        .tiptexts {
          font-size: 16px;
          font-weight: 400;
          color: #a7a7a7;
        }
      }

      .timeitem-line {
        margin-left: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .time-line {
          margin-left: 10px;
          margin-right: 10px;
          flex: 1;
          border-bottom: 1px solid #cccccc;
        }
      }

      .chartcontainer {
        width: 100%;
        height: 460px;
        padding: 0px;
        overflow-y: auto;

        .maincontain {
          margin: 10px 10px 10px 60px;
          position: relative;
          .basemap {
            width: 100%;
            min-height: 1020px;
            box-sizing: border-box;
            border: 1px solid #cccccc;
            border-bottom-color: transparent;
            position: relative;
            .item {
              width: calc(100% + 40px);
              top: -10px;
              left: -40px;
            }
          }
          .areamap {
            width: 100%;
            min-height: 30px;
            position: absolute;
            top: 0;
            z-index: 1;
            .item {
              width: 100%;
              top: 10px;
            }

            // position: absolute;
            .warn {
              background-color: #e6a23c;
            }
            .dang {
              background-color: #f56c6c;
            }
          }

          .item {
            position: absolute;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .num {
              user-select: none;
              white-space: nowrap;
            }
            .line {
              width: 100%;
              border-bottom: 1px solid #cccccc;
            }
            .cont {
              width: 100%;
            }
          }
          .item2 {
            position: absolute;
            top: 51px;
            left: -40px;
            width: calc(100% + 40px);
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .num {
              user-select: none;
              white-space: nowrap;
            }

            .line {
              width: 100%;
              // border-bottom: 1px solid red;
            }
          }
        }
      }

      .tip2 {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 6px;
        height: 26px;
        background: #fffbe6;
        border: 1px solid #ffaa00;
        border-radius: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
          margin-left: 20px;
          margin-right: 10px;
          width: 17px;
          height: 17px;
          line-height: 17px;
          text-align: center;
          border-radius: 50%;
          background: #ffaa00;
          color: white;

          .tiptext {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
}

::v-deep .el-upload-list__item-name {
  width: 60%;
}
}

</style>
